import { usePage } from '@inertiajs/vue3'

export const hasSubscription = (limit) =>
  usePage().props._user.active_company.subscriptionLimit[limit] === true
export const hasCrmAccess = () => hasSubscription('has_crm_access')
export const hasAPIAccess = () => hasSubscription('has_api_access')
export const hasCustomMailerAccess = () =>
  hasSubscription('has_custom_mailer_access')
export const hasChatAccess = () => hasSubscription('has_chat_access')
export const hasCustomSmsSender = () => hasSubscription('has_custom_sms_sender')
