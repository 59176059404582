<template>
  <Menu
    v-slot="{ open }"
    as="div"
    :class="['relative cursor-pointer text-[#3d4146]', containerClass]"
    :data-test="name"
  >
    <div class="">
      <MenuButton
        :disabled="disabled"
        :class="[
          open ? 'border-[#9c63ff] text-[#9c63ff]' : '',
          'flex w-full items-center rounded border border-[#ced4da] py-[7px] pl-[7px] pr-[12px] text-[13px] outline-none focus:border-[#a944b3cc]',
          btnClass,
          disabled && 'bg-gray-100 opacity-80',
        ]"
      >
        <span
          :class="[
            'relative block flex-1 truncate pl-0 pr-10 text-left',
            isValueEmpty && 'text-[#3d4146] text-opacity-50',
            placeholderClass,
          ]"
          >{{ $t(getValueLabel) }}</span
        >
        <FontAwesomeIcon icon="caret-down" class="text-xs" />
      </MenuButton>
    </div>

    <MenuItems
      as="div"
      :class="[
        'absolute z-50 max-h-[150px] w-full overflow-y-auto rounded border border-[rgba(0,0,0,.15)] bg-white outline-none',
        dropdownDirection === 'bottom' ? 'top-10' : 'bottom-10',
      ]"
    >
      <input
        v-if="showSearch"
        v-model="searchText"
        type="text"
        placeholder="Search"
        class="sticky top-0 w-full border-b px-[10px] pt-[10px] pb-[7px] text-xs outline-none"
        autofocus
      />

      <div
        v-if="!filteredItems.length"
        class="bg-gray-50 px-[10px] pt-[10px] pb-[7px] text-center text-xs italic text-[#3d4146] text-opacity-50"
        v-text="$t('common.no_items')"
      />

      <MenuItem
        v-if="showPlaceholder && filteredItems.length"
        as="div"
        :class="[
          'cursor-pointer border-b border-[#e9eef4] px-[10px] pt-[10px] pb-[7px] text-xs text-[#3d4146] text-opacity-50 hover:bg-[#f8f9fa]',
          placeholderClass,
        ]"
        @click="
          () => {
            searchText = ''
            emit('update:modelValue', null)
            emit('change', null)
          }
        "
      >
        {{ placeholderText ? placeholderText : $t('common.select_one') }}
      </MenuItem>

      <MenuItem
        v-if="modelValue !== null && optional"
        as="div"
        :class="[
          'cursor-pointer border-b border-[#e9eef4] px-[10px] pt-[10px] pb-[7px] text-xs text-[#3d4146] text-opacity-50 hover:bg-[#f8f9fa]',
          placeholderClass,
        ]"
        @click="
          () => {
            searchText = ''
            emit('update:modelValue', null)
            emit('change', null)
          }
        "
      >
        {{ placeholderText ? placeholderText : $t('common.reset') }}
      </MenuItem>

      <MenuItem
        v-for="item in filteredItems"
        :key="item.value"
        :disabled="isOptionDisabled(item)"
        as="div"
        class="cursor-pointer border-b border-[#e9eef4] px-[10px] pt-[10px] pb-[7px] text-xs"
        :class="{
          'bg-gray-100 text-gray-500 hover:cursor-not-allowed':
            isOptionDisabled(item),
          'text-[#3d4146] hover:bg-[#f8f9fa]': !isOptionDisabled(item),
        }"
        @click="
          () => {
            let newValue = item[keyValue] !== undefined ? item[keyValue] : item
            searchText = ''
            emit('update:modelValue', newValue)
            emit('change', newValue)
          }
        "
      >
        {{ keyLabel ? getNestedObjectValue(item, keyLabel) : item }}
      </MenuItem>
    </MenuItems>
  </Menu>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { computed, ref } from 'vue'

const props = defineProps({
  modelValue: {
    type: [String, Boolean, Number, Object, null],
    default: null,
  },
  value: {
    type: [String, Boolean, Number, Object, null],
    default: null,
  },
  items: {
    type: Object,
    required: true,
  },
  keyValue: {
    type: [String, null],
    default: null,
  },
  keyLabel: {
    type: [String, null],
    default: null,
  },
  btnClass: {
    type: String,
    default: '',
  },
  placeholderClass: {
    type: String,
    default: '',
  },
  containerClass: {
    type: [Array, Object, String],
    default: '',
  },
  dropdownDirection: {
    type: String,
    default: 'bottom',
  },
  showPlaceholder: {
    type: Boolean,
    default: false,
  },
  optional: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: '',
  },
  placeholderText: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  disabledItemKey: {
    type: String,
    required: false,
    default: () => 'disabled',
  },
  showSearch: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue', 'change'])

const isValueEmpty = computed(() => {
  // Check for empty values
  let emptyValues = [null, undefined, '0', '']
  return emptyValues.includes(props.modelValue)
})

const searchText = ref('')

const filteredItems = computed(() => {
  if (searchText.value !== '') {
    return props.items.filter((field) => {
      const label = props.keyLabel
        ? getNestedObjectValue(field, props.keyLabel)
        : field

      return (
        label && label.toLowerCase().includes(searchText.value.toLowerCase())
      )
    })
  }

  return props.items
})

const selectedItem = computed(() => {
  return props.items.find((item) => {
    // Check if item is an object
    // if `true` return value from Object Key
    if (typeof item === 'object') {
      return item[props.keyValue] === props.modelValue
    }

    return item === props.modelValue
  })
})

const getValueLabel = computed(() => {
  // Check if prop `modelValue` or `value` is empty
  if (isValueEmpty.value) {
    if (props.placeholderText) {
      return props.placeholderText.toString()
    }

    return 'common.select_one'
  }

  // Check if prop `keyLabel` is not empty
  if (props.keyLabel) {
    if (typeof props.modelValue === 'object') {
      return selectedItem.value[props.keyLabel].toString()
    }

    return getNestedObjectValue(selectedItem.value, props.keyLabel).toString()
  }

  return selectedItem.value.toString()
})

const getNestedObjectValue = (obj, key) => {
  // Method for accessing nested objects by string path
  // e.g( object['booking.event'] )

  // Usage: getNestedObjectValue(object, 'booking.event')

  key = key.replace(/\[(\w+)\]/g, '.$1') // Convert indexes to properties
  key = key.replace(/^\./, '') // Strip a leading dot
  let keys = key.split('.')
  for (let i = 0, n = keys.length; i < n; ++i) {
    let k = keys[i]
    if (k in obj) {
      obj = obj[k]
    } else {
      return
    }
  }
  return obj
}

let resetValue = ref(props.modelValue)
const isOptionDisabled = (item) => {
  if (item.limit === 0) {
    return true
  }

  if (item.id === resetValue.value) {
    return false
  }

  return item[props.disabledItemKey]
}
</script>
