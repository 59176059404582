<template>
  <slot />
  <Modal
    :show="true"
    :title="$t('Switch Company')"
    :width="500"
    vertical-position="center"
    @close="emit('close')"
  >
    <SelectInput
      v-model="selectedCompany"
      :items="
        usePage().props._companies.map((company) => ({
          ...company,
          name: `${company.name} ${company.deactivated_at ? '(inactive)' : ''}`,
        }))
      "
      key-label="name"
      key-value="id"
      @change="changeCompany"
    />
  </Modal>
</template>

<script setup>
import Modal from '@/Components/Modal.vue'
import SelectInput from '@/Components/common/SelectInput.vue'
import { useRoute } from '@/composables/route'
import { useForm, usePage } from '@inertiajs/vue3'
import { ref } from 'vue'

const emit = defineEmits(['close'])

const user = usePage().props._user
const selectedCompany = ref(user.active_company?.id)
const form = useForm({})

const changeCompany = async (company) => {
  form.post(
    useRoute('companies.setActive', {
      company,
    }),
  )
  emit('close')
}
</script>
