<template>
  <div class="block md:hidden">
    <Menu as="div" class="relative min-w-[40px]">
      <MenuButton
        class="flex h-[35px] w-[40px] items-center justify-center rounded-[5px]"
        :class="$page.component === 'Dashboard' && 'hidden'"
      >
        <FontAwesomeIcon :icon="['fal', 'bars']" class="text-2xl text-white" />
      </MenuButton>

      <MenuItems
        as="div"
        class="absolute top-[56px] w-[200px] overflow-y-auto bg-white p-[5px_15px_10px_15px] tracking-[0.2px] text-[#7b8190] subpixel-antialiased shadow-[0_5px_6px_rgb(52,58,64,0.10)] overflow-y-auto max-h-[470px]"
      >
        <MenuItem
          as="div"
          class="block cursor-pointer border-b border-dashed border-[#dbdbdb] py-3 text-xs font-bold"
          @click="router.get(useRoute('dashboard'))"
        >
          {{ $t('common.my_events') }}
        </MenuItem>
        <MenuItem
          v-if="usePermission('contact:list') && hasCrmAccess()"
          as="div"
          class="block cursor-pointer border-b border-dashed border-[#dbdbdb] py-3 text-xs font-bold"
          @click="router.get(useRoute('contacts.index'))"
        >
          {{ $t('contact.my_contacts') }}
        </MenuItem>
        <div v-if="pageHaveEvent">
          <template v-for="menu in activeMenuElements" :key="menu.label">
            <!-- Check if menu has child and dropdown -->
            <template v-if="!menu.desktopOnly && menu.dropdown">
              <template v-for="child in menu.child" :key="child.label">
                <!-- Check if child has another submenu -->
                <template v-if="menu.childSubmenu">
                  <MenuItem
                    v-for="childSubmenu in child.submenu"
                    :key="childSubmenu.label"
                    as="div"
                    class="block cursor-pointer border-b border-dashed border-[#dbdbdb] py-3 text-xs font-bold"
                    :class="menu.desktopOnly && 'text-[#bbb]'"
                    @click="
                      router.get(
                        useRoute(childSubmenu.route, {
                          event: pageHaveEvent?.id,
                        }),
                      )
                    "
                  >
                    {{ $t(`common.${childSubmenu.label}`) }}
                  </MenuItem>
                </template>

                <MenuItem
                  v-else
                  as="div"
                  class="block cursor-pointer border-b border-dashed border-[#dbdbdb] py-3 text-xs font-bold"
                  :class="menu.desktopOnly && 'text-[#bbb]'"
                  @click="
                    router.get(
                      useRoute(child.route, { event: pageHaveEvent?.id }),
                    )
                  "
                >
                  {{ $t(`common.${child.label}`) }}
                </MenuItem>
              </template>
            </template>

            <MenuItem
              v-else
              as="div"
              class="block cursor-pointer border-b border-dashed border-[#dbdbdb] py-3 text-xs font-bold"
              :class="menu.desktopOnly && 'text-[#bbb]'"
              @click="
                menu.desktopOnly
                  ? (isDisabledMenuAlertShown = true)
                  : router.get(
                      useRoute(menu.route, { event: pageHaveEvent?.id }),
                    )
              "
            >
              {{ $t(`common.${menu.label}`) }}
            </MenuItem>
          </template>
        </div>
      </MenuItems>
    </Menu>
  </div>

  <DisabledMenuAlert
    :show="isDisabledMenuAlertShown"
    @confirm="isDisabledMenuAlertShown = false"
  />
</template>

<script setup>
import DisabledMenuAlert from '@/Components/DisabledMenuAlert.vue'
import { useMenus } from '@/composables/menu'
import { usePermission } from '@/composables/permission'
import { useRoute } from '@/composables/route'
import { hasCrmAccess } from '@/composables/subscription'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { router, usePage } from '@inertiajs/vue3'
import { ref, computed } from 'vue'

const pageHaveEvent = computed(() => usePage().props.event)
const activeMenuElements = computed(() =>
  useMenus.filter((menu) => usePermission(menu.permission)),
)

const isDisabledMenuAlertShown = ref(false)
</script>
