<template>
  <TransitionRoot appear :show="show">
    <Dialog
      as="div"
      open
      class="fixed top-0 -left-[2px] z-50 h-screen w-full overflow-y-auto tracking-[0.2px] subpixel-antialiased"
      @close="emit('close')"
    >
      <TransitionChild
        as="template"
        enter="duration-200 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogOverlay
          class="fixed top-0 left-0 h-full w-full bg-black bg-opacity-50"
        />
      </TransitionChild>

      <TransitionChild
        as="template"
        enter="duration-200 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="h-screen overflow-y-auto">
          <div
            :class="[
              'flex min-h-screen justify-center py-10',
              verticalPosition === 'top' ? 'items-start' : 'items-center',
            ]"
          >
            <div
              :class="[
                'relative max-w-[95%] rounded-[10px] bg-white shadow-[0px_6px_19px_0px_rgba(182,182,182,0.35)]',
                wrapperClass,
              ]"
              :style="{
                width: !width
                  ? 'auto'
                  : typeof width !== 'number'
                    ? width
                    : width + 'px',
              }"
            >
              <div :class="['p-[17px_17px_25px_17px]', bodyClass]">
                <img
                  v-if="showAlertIcon"
                  :src="Vapor.asset('images/attention.png')"
                  alt=""
                  class="mx-auto my-[15px] h-[175px] w-[175px]"
                />
                <slot>
                  <div :class="['text-center', contentClass]">
                    <h3
                      class="mb-[10px] text-2xl font-semibold text-[#797979]"
                      v-text="title || $t('common.are_you_sure')"
                    />
                    <p
                      class="text-sm font-light text-[#797979]"
                      v-text="message"
                    />
                  </div>
                </slot>
                <slot name="buttons">
                  <div class="mt-6 flex items-center justify-center gap-2">
                    <button
                      v-if="displayCancelButton"
                      type="button"
                      class="h-[35px] min-w-[100px] rounded-[5px] bg-[#e9eef4] px-[25px] text-[13px] text-[#9C63FF] outline-none"
                      @click="emit('close')"
                      v-text="cancelText || $t('common.cancel')"
                    />
                    <button
                      type="button"
                      class="h-[35px] min-w-[100px] rounded-[5px] bg-[#9c63ff] px-[25px] text-[13px] text-white outline-none disabled:opacity-50"
                      :disabled="confirmLoading"
                      @click="emit('confirm')"
                    >
                      <FontAwesomeIcon
                        v-if="confirmLoading"
                        :icon="['fal', 'spinner']"
                        class="text-sm text-white"
                        spin
                      />
                      <span v-else v-text="confirmText || $t('common.yes')" />
                    </button>
                  </div>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
} from '@headlessui/vue'
import Vapor from 'laravel-vapor'

defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  title: {
    type: [String, null],
    default: null,
  },
  message: {
    type: [String, null],
    default: null,
  },
  width: {
    type: [Number, String, null],
    default: 478,
  },
  bodyClass: {
    type: String,
    default: '',
  },
  contentClass: {
    type: String,
    default: '',
  },
  wrapperClass: {
    type: String,
    default: '',
  },
  verticalPosition: {
    type: String,
    default: 'center',
  },
  showAlertIcon: {
    type: Boolean,
    default: true,
  },
  confirmText: {
    type: String,
    required: false,
    default: null,
  },
  confirmLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
  cancelText: {
    type: String,
    required: false,
    default: null,
  },
  displayCancelButton: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['close', 'confirm'])
</script>
