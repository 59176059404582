<template>
  <header
    class="bg-violet-gradient z-[50] w-full pl-[10px] pr-[14px] tracking-[0.2px] subpixel-antialiased md:px-[20px] min-[1700px]:px-[8%]"
  >
    <div class="flex h-[78px] items-center justify-between">
      <MobileMenu />

      <div
        class="flex flex-1 gap-2 items-center md:items-start justify-center md:mr-5 md:flex-none"
      >
        <img
          :src="Vapor.asset('images/airlst-logo-white.png')"
          alt="airlst-logo-white"
          class="mb-[4px] w-[135px] cursor-pointer"
          data-test="logo-img"
          @click="router.get(useRoute('dashboard'))"
        />

        <div
          v-if="planName"
          class="hidden lg:block text-[8px] font-medium border-[0.2px] border-[#9A8BA6] text-[#9A8BA6] py-[1px] px-[6px] rounded-[31px] bg-[rgba(156,99,255,0.3)]"
          v-text="$t(planName)"
        />
      </div>

      <div
        class="mr-5 hidden h-full flex-1 items-center justify-center gap-[20px] md:flex"
      >
        <Suspense
          v-if="
            usePermission('event:list') &&
            usePage().props._user.active_company.deactivated_at === null
          "
        >
          <SwitchEvent />
        </Suspense>

        <div
          v-if="usePermission('contact:list') && hasCrmAccess()"
          :class="[
            'group mr-2 flex h-full cursor-pointer items-center gap-[6px]',
            $page.component.includes('Contacts')
              ? 'text-white'
              : 'text-[rgba(255,255,255,0.5)] hover:text-white',
          ]"
          data-test="address-book-icon-link"
          @click="router.get(useRoute('contacts.index'))"
        >
          <FontAwesomeIcon
            :icon="['fas', 'address-book']"
            class="mb-1 text-base text-[#9C63FF]"
          />
          <span
            v-if="!pageHaveEvent"
            :class="[
              'mr-[2px] inline-block border-b-2 py-[8px] text-sm',
              $page.component.includes('Contacts')
                ? 'border-[#9c63ff]'
                : 'whitespace-nowrap border-transparent group-hover:border-[#9c63ff]',
            ]"
            v-text="$t('contact.my_contacts')"
          />
        </div>

        <div
          v-if="pageHaveEvent"
          class="flex h-full items-start gap-[25px] xl:gap-[18px]"
        >
          <div
            v-for="menu in activeMenuElements"
            :key="menu.label"
            class="relative flex h-full items-center"
          >
            <Link
              v-if="!menu.dropdown"
              :class="[
                'group flex items-center gap-[5px] text-sm 2xl:gap-[10px]',
                $page.component === menu?.name
                  ? 'text-white'
                  : 'text-[rgba(255,255,255,0.5)] hover:text-white',
              ]"
              :href="useRoute(menu.route, { event: pageHaveEvent?.id })"
              :data-tutorial-selector="menu.selector || null"
            >
              <FontAwesomeIcon
                v-if="menu.icon"
                :icon="menu.icon"
                class="mb-[2px] py-[25px] text-xs 2xl:py-0 2xl:text-sm text-[#9C63FF]"
              />
              <span
                :class="[
                  'mr-[2px] hidden whitespace-nowrap border-b-2 py-[8px] text-xs xl:inline-block 2xl:text-sm',
                  $page.component === menu?.name
                    ? 'border-[#9c63ff]'
                    : 'border-transparent group-hover:border-[#9c63ff]',
                ]"
                v-text="$t(`common.${menu.label}`)"
              />
            </Link>

            <NavDropdown
              v-else
              :title="$t(`common.${menu.label}`)"
              :menu="menu"
            />
          </div>
        </div>
      </div>

      <div class="flex items-center justify-end">
        <div class="md:mr-[16px] items-center flex relative z-[11]">
          <Notifications
            menu-btn-class="hidden md:block"
            menu-class="absolute right-0 md:right-auto md:relative"
            dropdown-class="!fixed md:!absolute  left-2 md:left-auto top-[80px] md:top-auto w-[95%] md:w-96"
          />

          <a
            :href="useHelpCenterUrl()"
            target="_blank"
            rel="noopener"
            class="cursor-pointer hidden md:block"
          >
            <FontAwesomeIcon
              :icon="['fad', 'circle-question']"
              class="text-[24px] text-[#9c63ff]"
            />
          </a>
        </div>

        <UserDropdown />

        <SelectLanguage dropdown-class="top-[48px] rounded-none" />
      </div>
    </div>
  </header>
</template>

<script setup>
import SwitchEvent from '@/Components/Events/SwitchEvent.vue'
import SelectLanguage from '@/Components/Language/SelectLanguage.vue'
import NavDropdown from '@/Components/NavDropdown.vue'
import Notifications from '@/Components/Notifications/Notifications.vue'
import MobileMenu from '@/Components/UserSettings/MobileMenu.vue'
import UserDropdown from '@/Components/UserSettings/UserDropdown.vue'
import { useMenus, useHelpCenterUrl } from '@/composables/menu'
import { usePermission } from '@/composables/permission'
import { useRoute } from '@/composables/route'
import { hasCrmAccess } from '@/composables/subscription'
import { router, Link, usePage } from '@inertiajs/vue3'
import Vapor from 'laravel-vapor'
import { computed } from 'vue'

const pageHaveEvent = computed(() => usePage().props.event)

const activeMenuElements = computed(() =>
  useMenus.filter((menu) => usePermission(menu.permission)),
)

const planName = computed(() => {
  const company = usePage().props._user?.active_company
  if (company.subscribed) {
    return ''
    // TODO: add the correct Product Name here
    // return 'Product name'
  }

  if (company.enterprise) {
    return 'subscriptions.enterprise'
  }

  return 'common.free'
})
</script>
